<template>
  <div>
    <!-- Stepper navigation (STP)-->
    <template v-if="isStepperActivated">
      <AppNavBar v-if="mq.current === 'xs'" class="shadow-ria-1" show-progress-bar="sm">
        <template #left>
          <!-- Back button -->
          <AppButton
            v-if="businessOwnersOptions.hideBackButton"
            theme="icon"
            class="icon"
            @click="stpBackButton"
          >
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>
        </template>

        <!-- Title -->
        <template>
          <div v-if="businessOwnersOptions.ownerName">
            {{ businessOwnersOptions.ownerName }}
          </div>
          <div v-else-if="stepperOptions.content.mobile.customTitle">
            {{ stepperOptions.content.mobile.customTitle }}
          </div>
          <div v-else-if="isStepperOptionsHidden">{{ stepperOptions.content.top.title }}</div>
          <div v-else-if="stepperOptions.content.mobile.mobileTitle">
            {{ stepperOptions.content.mobile.mobileTitle }}
          </div>
        </template>

        <template v-if="isStepperOptionsHidden"> </template>
        <template v-else-if="stepperOptions.content.mobile.mobileTitle"> </template>

        <template v-if="!stepperOptions.content.mobile.disabledRightIcon" #right>
          <!-- Close button -->
          <AppButton
            theme="icon"
            class="icon"
            @click="$router.push(navigationConfig.router.navbar)"
          >
            <AppIcon name="Go to pending actions">
              <IconClose />
            </AppIcon>
          </AppButton>
        </template>
      </AppNavBar>
      <XeStepper
        v-if="mq.current !== 'xs'"
        :hide-content="isStepperOptionsHidden"
        :options="stepperOptions"
        :items="stepperItems"
        @selected-item="setStepperItemActive"
      />
    </template>
    <!-- Main navigation -->
    <template v-else>
      <!-- Display only on tablets & desktop devices -->
      <XeNavSidebar
        v-if="mq.current !== 'xs'"
        :items="navigationItems"
        :href="navigationConfig.link.href"
        :aria-label="navigationConfig.link.img.alt"
        :class="isStpAccount ? 'dashboard-navbar-stp' : null"
        @disabled-item="disabledItem"
      >
        <template #header>
          <!-- Logo -->
          <AppLink class="dashboard-sidebar-logo" @click="goToPendingActions">
            <img :src="logoSrc" :alt="navigationConfig.link.img.alt" />
          </AppLink>

          <!-- CTA button -->
          <div class="dashboard-sidebar-footer">
            <div v-tooltip="tooltipOptions()">
              <AppButton
                class="dashboard-sidebar-button"
                disabled
                :type="navigationConfig.button.type"
              >
                {{ navigationConfig.button.text }}
              </AppButton>
            </div>
          </div>
        </template>
      </XeNavSidebar>

      <!-- Display only in "AdditionalInfo" & "AuthorizedPersonnel" page -->
      <AppNavBar
        v-else-if="isNavbarActivated"
        :is-fixed="!isNavbarActivated"
        :class="[isNavbarActivated ? 'dashboard-navbar-section' : null]"
        show-progress-bar="sm"
      >
        <template #left>
          <!-- Additional Info -->
          <AppButton
            theme="icon"
            class="icon"
            @click="$router.push(navigationConfig.router.navbar)"
          >
            <AppIcon name="Go Back">
              <IconChevronLeft />
            </AppIcon>
          </AppButton>

          <!-- Authorized Personnel -->
          <div v-if="isNavbarActivated">
            <AppLink
              v-if="mq.current !== 'xs'"
              :href="navigationConfig.link.href"
              :aria-label="navigationConfig.link.img.alt"
            >
              <img class="navbar-logo" :src="logoSrc" :alt="navigationConfig.link.img.alt" />
            </AppLink>
          </div>
        </template>
        <!-- Title -->
        <template>
          <!-- Only additional info -->
          <div v-if="currentRoute === 'AdditionalInfo'">{{ questionTitle }}</div>

          <!-- Only Authorized personnel -->
          <div v-if="currentRoute === 'AuthorizedPersonnel'">{{ authorizedPersonnelTitle }}</div>
        </template>
      </AppNavBar>

      <!-- Display only on mobile devices -->
      <XeNavMobile
        v-else
        :class="isStpAccount ? 'dashboard-navbar-mobile-stp' : null"
        theme="sidebar"
        :logo-link="logoSrc"
        logo-route
        :items="navigationItems"
        @disabled-item="disabledItem"
      >
        <!-- Logo -->
        <template #left>
          <AppLink class="dashboard-sidebar-logo" @click="goToPendingActions">
            <img :src="logoSrc" :alt="navigationConfig.link.img.alt" />
          </AppLink>
        </template>

        <!-- CTA -->
        <template #footer>
          <AppButton disabled :type="navigationConfig.button.type">
            {{ navigationConfig.button.text }}
          </AppButton>
        </template>
      </XeNavMobile>
    </template>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'

// Store
import { storeToRefs } from 'pinia'
import { useDashboardStore } from '@/stores/dashboard'
import { useAuthStore } from '@/stores/auth'
import { useCountriesStore } from '@/stores/countries'

// Tooltip Styles
import 'floating-vue/dist/style.css'

// Components
import AppButton from '@en-ui/components/AppButton/AppButton'
import AppLink from '@en-ui/components/AppLink/AppLink'
import AppIcon from '@en-ui/components/AppIcon/AppIcon'
import AppNavBar from '@en-ui/components/AppNavBar/AppNavBar'
import XeNavSidebar from '@/components/XeNavSidebar/XeNavSidebar'
import XeNavMobile from '@/components/XeNavMobile/XeNavMobile'
import XeStepper from '@/components/XeStepper/XeStepper'
import { IconChevronLeft, IconClose } from '@moneytransfer.ui/euronet-icons'

// Composables
import { useRouter, checkCurrentRouteAndRedirect } from '@/composables/useRouter'

export default {
  components: {
    AppButton,
    AppLink,
    AppIcon,
    AppNavBar,
    XeNavMobile,
    XeNavSidebar,
    XeStepper,
    IconChevronLeft,
    IconClose,
  },
  props: {
    navigationConfig: {
      type: Object,
      default: () => ({}),
    },
    mq: {
      type: Object,
      default: () => ({}),
    },
    isPendingActionsRoute: {
      type: Boolean,
      default: false,
    },
    isNavbarActivated: {
      type: Boolean,
      default: false,
    },
    currentRoute: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    /*
     *  Navigation setup
     */
    const router = useRouter()
    const dashboardStore = useDashboardStore()
    const authStore = useAuthStore()
    const countriesStore = useCountriesStore()
    const { businessOwnersOptions } = storeToRefs(dashboardStore)

    // Setup navigation layout (Navigation or Stepper)
    const stepperOptions = computed(() => dashboardStore.getStepperOptions)
    const isStepperOptionsHidden = computed(
      () => dashboardStore.getStepperOptionsHiddenFlag || dashboardStore.isStepperHidden
    )
    const stepperItems = computed(() => dashboardStore.getStepperItems(props.currentRoute))

    // Check for active step
    const isStepperActivated = computed(() => dashboardStore.getIsStepperActivated)

    // Authorized Personnel Title
    const authorizedPersonnelTitle = computed(() => {
      const corpProfile = dashboardStore.corpProfile
      return (
        countriesStore.getCountrySpecificText(
          'AuthorizedPersonnelTitle',
          corpProfile.registrationAddress?.country?.toUpperCase()
        ) || 'Authorized Personnel'
      )
    })
    // Get is STP
    const isStpAccount = computed(() => dashboardStore.getAccountIsAllowedForStp)
    // AdditionalInfo title
    const questionTitle = ref('Pending Actions - Questions')

    // Get src XE logo location
    const logoSrc = computed(() => {
      const { link } = props.navigationConfig
      return require(`@/assets/images/${link.img.src}`)
    })

    // Set tooltip options adding content property function
    const tooltipOptions = () => {
      const { tooltip } = props.navigationConfig
      return {
        content: tooltipContent(true),
        ...tooltip.options,
      }
    }

    // Function added above to set tooltip content
    const tooltipContent = (isDisabled) => {
      const { tooltip } = props.navigationConfig
      return isDisabled ? `${tooltip.content}` : null
    }

    // Set navigation items using flags to insert function over the object
    const navigationItems = computed(() => {
      const { items } = props.navigationConfig
      return items.map((item) => {
        if (item.isTooltipOptions) {
          item['tooltipOptions'] = () => tooltipOptions(item.isDisabled)
        }
        if (item.isClickEvent) {
          item['click'] = async () => await authStore.logout()
        }
        return item
      })
    })

    // Go to processing information Page
    const disabledItem = () => router.push({ name: 'ActionRequired' })
    // Go to pending Actions
    const goToPendingActions = () => checkCurrentRouteAndRedirect(router, 'PendingActions')

    // back button
    const stpBackButton = () => {
      if (
        businessOwnersOptions.value?.suggestedStepCompleted &&
        props.currentRoute === 'businessOwners'
      ) {
        dashboardStore.businessOwnersOptions = { suggestedStepCompleted: false }
        return
      }
      router.push(stepperOptions.value.content.top.routeTo)
    }

    // Set active item for stepper component
    const setStepperItemActive = (itemId) => {
      const currentItems = ref(stepperItems.value)
      currentItems.value = stepperItems.value.map((item) => {
        item.id === itemId ? (item['activeItem'] = true) : (item['activeItem'] = false)
        return item
      })
      dashboardStore.setStepperItems({
        id: props.currentRoute,
        items: currentItems.value,
      })
    }

    return {
      disabledItem,
      goToPendingActions,
      tooltipOptions,
      setStepperItemActive,
      authorizedPersonnelTitle,
      questionTitle,
      navigationItems,
      logoSrc,
      stepperOptions,
      isStepperActivated,
      stepperItems,
      isStpAccount,
      isStepperOptionsHidden,
      businessOwnersOptions,
      stpBackButton,
    }
  },
}
</script>

<style lang="postcss" scoped>
.dashboard-navbar {
  @apply flex-col h-screen;
  @apply bg-white;

  @screen sm {
    @apply bg-tertiary-gray-darker;
  }

  /* Improve this after merge stepper component */
  &-stp {
    &:hover {
      @apply overflow-y-hidden;
    }

    @screen sm {
      @apply w-48;

      >>> .nav-sidebar-links {
        @apply pt-8;
      }

      .dashboard-sidebar-logo {
        @apply w-full;
      }

      .dashboard-sidebar-footer {
        @apply mx-4 p-0;
      }

      .dashboard-sidebar-button {
        @apply w-full;

        >>> .button-inner {
          @apply px-0;
        }
      }
    }

    @screen md {
      @apply w-64;
    }
  }

  &-section {
    @apply fixed w-full bg-white z-10;

    .navbar-container {
      @apply shadow-ria-1;

      @screen sm {
        @apply shadow-none;
      }

      .navbar-center {
        @apply whitespace-normal;
      }
    }
  }

  /* Override default style for stepper component */
  &-stepper {
    &.step-wrapper {
      @screen sm {
        @apply flex justify-center;
      }
    }

    .step,
    .step--complete {
      &::after {
        content: '';
        @apply h-2 w-2 rounded-full;
        @apply absolute top-0 z-10;
        left: 50%;
        margin-left: -3px;
        margin-top: -3px;
      }
    }

    .step {
      &::after {
        @apply bg-tertiary-grey-darkest;
      }

      &-text {
        @apply type-caption-bold text-text-gray-primary;
      }
    }

    .step--complete {
      &::after {
        @apply bg-main-cyan;
      }
    }
  }
}

.dashboard-sidebar-logo {
  @screen sm {
    @apply pl-8 py-6;
  }

  img {
    @apply w-9;

    @screen sm {
      @apply w-12;
    }
  }

  &:focus {
    @apply outline-none;
  }
}

.dashboard-sidebar-footer {
  @apply py-6 pl-9;
}

.dashboard-sidebar-button {
  @apply w-48;

  &:disabled&:not(.button--loading) {
    @apply bg-orange-button-disabled text-white;
  }
}

/* Custom tooltip styles */
.sidebar-tooltip {
  left: 164px !important;
  padding: 2px 2rem;
  max-width: 410px;

  .tooltip-inner {
    padding: 0.3rem 1rem;
    font-size: 0.9rem;
  }

  .tooltip-arrow {
    border-width: 11px 7px 0px 7px !important;
    bottom: -8px !important;
    left: 50px !important;
  }
}

.dashboard-navbar-mobile-stp {
  @apply fixed w-full;
}
</style>
