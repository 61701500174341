<template>
  <Component
    :is="htmlEle"
    :href="localHref"
    :rel="rel"
    :target="localTarget"
    :type="type"
    :analytics-name="analyticsName"
    class="link"
    :class="classes"
    @click="$emit('click', $event)"
  >
    <div v-if="$slots.leftIcon" class="link-left-icon">
      <!-- @slot leftIcon: For the left Icon -->
      <slot name="leftIcon" />
    </div>
    <!-- @slot default: link content -->
    <span class="link-text">
      <slot />
    </span>
    <div v-if="$slots.rightIcon" class="link-right-icon">
      <!-- @slot rightIcon: For the right Icon -->
      <slot name="rightIcon" />
    </div>
  </Component>
</template>

<script>
import { computed } from '@vue/composition-api'
export const themes = ['primary', 'secondary']
export default {
  name: 'AppLink',
  props: {
    href: {
      type: String,
      default: null,
      required: false,
    },
    target: {
      type: String,
      default: null,
      validator: (value) => {
        return value !== '_blank'
      }
    },
    theme: {
      type: String,
      default: 'primary',
      validator: (value) => {
        return themes.indexOf(value) !== -1
      },
    },
    analyticsName: {
      type: String,
      default: '',
      required: !!process.env.VUE_APP_ANALYTICS_ENABLED,
    },
  },
  setup(props, { slots }) {
    const classes = computed(() => {
      return {
        /* Apply styling so icons are aligned correctly while not preventing wrapping on long text links without icons */
        'link--has-icon': slots.leftIcon || slots.rightIcon,
        'link--secondary': props.theme === 'secondary',
      }
    })
    return {
      htmlEle: computed(() => {
        if (props.href) {
          return 'a'
        } else {
          return 'button'
        }
      }),
      rel: computed(() => {
        if (props.href) {
          return 'nofollow noreferrer noopener'
        } else {
          return null
        }
      }),
      localHref: computed(() => {
        if (props.href) {
          return props.href
        } else {
          return null
        }
      }),
      localTarget: computed(() => {
        if (props.href) {
          return props.target
        } else {
          return null
        }
      }),
      classes,
      type: computed(() => {
        if (props.href) {
          return null
        } else {
          return 'button'
        }
      }),
    }
  },
}
</script>

<style scoped>
.link {
  word-break: break-word;
  @apply text-orange font-bold;
  &:hover {
    @apply underline;
  }
  &:active {
    @apply text-orange-button-pressed;
  }

  &.link--secondary {
    @apply text-secondary-text;
    &:active {
      @apply text-tertiary-text;
    }
  }
  /* Apply styling so icons are aligned correctly while not not preventing wrapping on long text links without icons */
  &.link--has-icon {
    @apply inline-flex items-center align-top;
  }
  .link-left-icon,
  .link-right-icon {
    @apply inline-flex items-center align-middle;
  }
  .link-left-icon {
    @apply mr-1_5;
  }
  .link-right-icon {
    @apply ml-1_5;
  }
}
</style>
