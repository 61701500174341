<template>
  <div class="relative flex flex-col items-center justify-center p-6">
    <button
      v-if="closable"
      class="absolute top-0 right-0 pr-4 pt-4 text-gray outline-none focus:outline-none"
      @click="onClose"
    >
      <AppIcon aria-label="Close">
        <IconClose />
      </AppIcon>
    </button>

    <img
      v-if="imgSrc"
      :src="imgSrc"
      :alt="alt"
      class="w-10 h-10"
    />
    <h2 v-if="title" class="type-h3 mt-4 text-center">
      <strong>{{ title }}</strong>
    </h2>
    <div class="w-full">
      <slot></slot>
    </div>
  </div>
</template>
  
<script>
import { computed } from '@vue/composition-api'
import AppIcon from '@/components/AppIcon/AppIcon'
import { IconClose } from '@moneytransfer.ui/euronet-icons'

export default {
  name: 'XeCardImageTitle',
  components: {
    AppIcon,
    IconClose,
  },
  emits: ['close'],
  props: {
      src: {
      type: String,
      required: false,
    },
    alt: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      default: '',
    },
    closable: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const imgSrc = computed(() => {
      const { src } = props
      if (!src) return undefined

      if (src && src.startsWith('http')) {
        return src
      }
      try {
        return require(`@/assets/images/${src}`)
      } catch {
        return ''
      }
    })

    const onClose = () => {
      emit('close')
    }

    return {
      imgSrc,
      onClose,
    }
  },
}
</script>

<style lang="postcss" scoped>
.card {
  @apply bg-white border border-gray-light
}
.card-bottom-left {
  @apply max-w-full rounded-t-xl;

  @screen sm {
    @apply max-w-sm rounded-xl;
  }
}

.fixed {
 @apply absolute z-50 
}
.fixed-bottom-left {
  @apply sticky bottom-0;

  @screen sm {
    @apply fixed right-0 bottom-0 p-8;
  }
}
</style>

