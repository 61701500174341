var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"nav-mobile"},[_c('nav',{staticClass:"nav-mobile-menu"},[_vm._t("left"),_c('button',{staticClass:"nav-mobile-icon-button",class:{
        active: _vm.isActive,
        deactivated: !_vm.isActive,
      },on:{"click":function($event){_vm.isActive = !_vm.isActive}}},[_c('AppIcon',{staticClass:"nav-mobile-icon",attrs:{"size":"24","name":"Menu"}},[(_vm.isActive)?_c('IconClose',{staticClass:"nav-mobile-icon-active"}):_c('IconMenu',{staticClass:"nav-mobile-icon-deactivated"})],1)],1)],2),_c('main',{staticClass:"nav-mobile-content",class:_vm.isActive ? 'nav-mobile-content-show' : null},[_c('div',{staticClass:"flex-1"},[_c('nav',{staticClass:"nav-mobile-menu"},[_vm._t("left"),_c('button',{staticClass:"nav-mobile-icon-button",class:{
            active: _vm.isActive,
            deactivated: !_vm.isActive,
          },on:{"click":function($event){_vm.isActive = !_vm.isActive}}},[_c('AppIcon',{staticClass:"nav-mobile-icon",attrs:{"size":"24","name":"Menu"}},[_c('Transition',{attrs:{"duration":"1000","name":"fade"}},[(_vm.isActive)?_c('IconClose',{staticClass:"nav-mobile-icon-active"}):_c('IconMenu',{staticClass:"nav-mobile-icon-deactivated"})],1)],1)],1)],2),_c('Transition',{attrs:{"duration":"1000","name":"nested"}},[_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.isActive),expression:"isActive"}],staticClass:"nav-mobile-links"},_vm._l((_vm.items),function(item,idx){return _c('li',{key:idx,staticClass:"nav-mobile-links-link inner"},[(!item.tooltipOptions)?_c('AppRipple',[(item.click || item.href)?_c('AppLink',_vm._g({staticClass:"nav-mobile-link",attrs:{"href":item.href || null,"target":item.target || null}},item.click ? { click: item.click } : {}),[_vm._v(" "+_vm._s(item.name)+" "),(item.icon)?_c('AppIcon',{staticClass:"ml-1 -m-1",attrs:{"size":18}},[_c(item.icon,{tag:"Component"})],1):_vm._e()],1):(item.isDisabled)?_c('p',{staticClass:"nav-mobile-links-disabled",class:item.isActive ? 'nav-mobile-links-active' : null,on:{"click":_vm.disabledItem}},[_vm._v(" "+_vm._s(item.name)+" ")]):(item.path)?_c('RouterLink',{attrs:{"custom":"","to":item.path},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var isActive = ref.isActive;
          var isExactActive = ref.isExactActive;
return [_c('AppLink',{staticClass:"nav-mobile-link",class:[
                    isActive && 'router-link-active',
                    isExactActive && 'router-link-exact-active' ],on:{"click":function($event){return _vm.goToTargetRoute(item.path.name)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)}):_vm._e()],1):_vm._e(),(item.tooltipOptions)?_c('AppRipple',{attrs:{"is-disabled":item.isDisabled}},[_c('VDropdown',{attrs:{"container":".nav-mobile-content","popper-class":"nav-mobile-tooltip","handle-resize":"","placement":"top","theme":"tooltip","triggers":['click'],"auto-hide":""},scopedSlots:_vm._u([{key:"popper",fn:function(){return [_vm._v(" "+_vm._s(_vm.getTooltipContent(item.tooltipOptions()))+" ")]},proxy:true}],null,true)},[(item.click || item.href)?_c('AppLink',_vm._g({staticClass:"nav-mobile-link",attrs:{"href":item.href || null,"target":item.target || null}},item.click ? { click: item.click } : {}),[_vm._v(" "+_vm._s(item.name)+" "),(item.icon)?_c('AppIcon',{staticClass:"ml-1 -m-1",attrs:{"size":18}},[_c(item.icon,{tag:"Component"})],1):_vm._e()],1):(item.isDisabled)?_c('p',{staticClass:"nav-mobile-links-disabled",on:{"click":_vm.disabledItem}},[_vm._v(" "+_vm._s(item.name)+" ")]):(item.path)?_c('RouterLink',{attrs:{"custom":"","to":item.path},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('AppLink',{staticClass:"nav-mobile-link",class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],on:{"click":function($event){return _vm.goToTargetRoute(item.path.name)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)}):_vm._e()],1)],1):_vm._e(),_vm._l((item.subItems),function(subItem,subIdx){return _c('div',{key:subIdx},[(!item.tooltipOptions)?_c('AppRipple',[(subItem.click || subItem.href)?_c('AppLink',_vm._g({staticClass:"nav-mobile-link",attrs:{"href":subItem.href || null,"target":subItem.target || null}},subItem.click ? { click: subItem.click } : {}),[_vm._v(" "+_vm._s(subItem.name)+" "),(item.icon)?_c('AppIcon',{staticClass:"ml-1 -m-1",attrs:{"size":18}},[_c(item.icon,{tag:"Component"})],1):_vm._e()],1):(subItem.isDisabled)?_c('p',{staticClass:"nav-mobile-links-disabled",on:{"click":_vm.disabledItem}},[_vm._v(" "+_vm._s(item.name)+" ")]):(subItem.path)?_c('RouterLink',{staticClass:"sub-item",class:{ 'router-link-disabled': subItem.isDisabled },attrs:{"to":subItem.path,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('AppLink',{class:[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active' ],on:{"click":function($event){return _vm.goToTargetRoute(subItem.path.name)}}},[_vm._v(" "+_vm._s(subItem.name)+" ")])]}}],null,true)}):_vm._e()],1):_vm._e(),(item.tooltipOptions)?_c('AppRipple',{attrs:{"is-disabled":subItem.isDisabled}},[_c('VDropdown',{attrs:{"container":".nav-mobile-content","popper-class":"nav-mobile-tooltip","handle-resize":"","placement":"top","theme":"tooltip","triggers":['click'],"auto-hide":""},scopedSlots:_vm._u([{key:"popper",fn:function(){return [_vm._v(" "+_vm._s(_vm.getTooltipContent(item.tooltipOptions()))+" ")]},proxy:true}],null,true)},[(subItem.click || subItem.href)?_c('AppLink',_vm._g({staticClass:"nav-mobile-link",attrs:{"href":subItem.href || null,"target":subItem.target || null}},subItem.click ? { click: subItem.click } : {}),[_vm._v(" "+_vm._s(subItem.name)+" "),(item.icon)?_c('AppIcon',{staticClass:"ml-1 -m-1",attrs:{"size":18}},[_c(item.icon,{tag:"Component"})],1):_vm._e()],1):(subItem.isDisabled)?_c('p',{staticClass:"nav-mobile-links-disabled",on:{"click":_vm.disabledItem}},[_vm._v(" "+_vm._s(item.name)+" ")]):(subItem.path)?_c('RouterLink',{staticClass:"sub-item",class:{ 'router-link-disabled': subItem.isDisabled },attrs:{"to":subItem.path,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('AppLink',{class:[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active' ],on:{"click":function($event){return _vm.goToTargetRoute(subItem.path.name)}}},[_vm._v(" "+_vm._s(subItem.name)+" ")])]}}],null,true)}):_vm._e()],1)],1):_vm._e()],1)})],2)}),0)])],1),_c('footer',{staticClass:"nav-mobile-footer"},[_c('div',[_vm._t("footer")],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }