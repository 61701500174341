<template>
  <nav class="nav-sidebar" aria-label="Main">
    <div>
      <header>
        <!-- @slot header: For any top content such as a Logo -->
        <slot name="header"> </slot>
      </header>
      <ul class="nav-sidebar-links">
        <!-- @slot items: For templating the sidebar items-->
        <slot name="items">
          <li
            v-for="(item, idx) in items"
            :key="idx"
            v-tooltip.top-start="item.tooltipOptions ? item.tooltipOptions() : {}"
            class="nav-sidebar-links-link"
          >
            <!-- Render item -->
            <AppRipple :is-disabled="item.isDisabled">
              <!-- A with href link or click handler -->
              <AppLink
                v-if="item.click || item.href"
                class="link"
                :href="item.href || null"
                :target="item.target || null"
                v-on="item.click ? { click: item.click } : {}"
              >
                {{ item.name }}
                <AppIcon v-if="item.icon" :size="18" class="ml-1 -m-1">
                  <Component :is="item.icon" />
                </AppIcon>
              </AppLink>
              <!-- Disabled Links -->
              <p
                v-else-if="item.isDisabled"
                class="nav-sidebar-links-disabled"
                :class="item.isActive ? 'nav-sidebar-links-active' : null"
                @click="$emit('disabled-item')"
              >
                {{ item.name }}
              </p>
              <!-- Vue router link  -->
              <RouterLink
                v-else-if="item.path"
                v-slot="{ isActive, isExactActive }"
                custom
                :to="item.path"
              >
                <AppLink
                  :class="[
                    isActive && 'router-link-active',
                    isExactActive && 'router-link-exact-active',
                  ]"
                  @click="goToTargetRoute(item.path.name)"
                >
                  {{ item.name }}
                </AppLink>
              </RouterLink>
            </AppRipple>
            <!-- Render subItem if exists -->
            <AppRipple
              v-for="(subItem, subIdx) in item.subItems"
              :key="subIdx"
              :is-disabled="subItem.isDisabled"
            >
              <!-- A with href link or click handler -->
              <AppLink
                v-if="subItem.click || subItem.href"
                class="link"
                :href="subItem.href || null"
                :target="subItem.target || null"
                v-on="subItem.click ? { click: subItem.click } : {}"
              >
                {{ subItem.name }}
                <AppIcon v-if="item.icon" :size="18" class="ml-1 -m-1">
                  <Component :is="item.icon" />
                </AppIcon>
              </AppLink>
              <!-- Disabled Links -->
              <p
                v-else-if="subItem.isDisabled"
                v-tooltip="subItem.tooltipOptions ? subItem.tooltipOptions() : {}"
                class="nav-sidebar-links-disabled"
                @click="$emit('disabled-item')"
              >
                {{ item.name }}
              </p>
              <!-- Vue router link  -->
              <RouterLink
                v-else-if="subItem.path"
                v-slot="{ isActive, isExactActive }"
                :to="subItem.path"
                custom
                class="sub-item"
                :class="{ 'router-link-disabled': subItem.isDisabled }"
              >
                <AppLink
                  :class="[
                    isActive && 'router-link-active',
                    isExactActive && 'router-link-exact-active',
                  ]"
                  @click="goToTargetRoute(subItem.path.name)"
                >
                  {{ subItem.name }}
                </AppLink>
              </RouterLink>
            </AppRipple>
          </li>
        </slot>
      </ul>
    </div>
    <footer>
      <!-- @slot footer: For any bottom content such as a Call to Action -->
      <slot name="footer"> </slot>
    </footer>
  </nav>
</template>
<script>
import AppLink from '@en-ui/components/AppLink/AppLink'
import AppNavSidebar from '@en-ui/components/AppNavSidebar/AppNavSidebar'
import { useRouter, checkCurrentRouteAndRedirect } from '@/composables/useRouter'
import AppIcon from '@/components/AppIcon/AppIcon'
import { IconLaunch } from '@moneytransfer.ui/euronet-icons'

export default {
  name: 'AppNavSidebar',
  components: {
    AppLink,
    AppNavSidebar,
    AppIcon,
    IconLaunch,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    logoLink: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup() {
    const router = useRouter()
    const goToTargetRoute = (nameRoute) => checkCurrentRouteAndRedirect(router, nameRoute)

    return {
      goToTargetRoute,
    }
  },
}
</script>

<style lang="postcss" scoped>
.nav-sidebar {
  @apply w-64 fixed left-0;
  @apply bg-tertiary-gray-darker border-r border-gray-lighter;
  @apply flex flex-col justify-between;
  overflow: hidden;

  &:hover {
    @apply overflow-y-hidden;
  }

  @screen sm {
    @apply min-h-full h-screen;
  }

  .nav-sidebar-links {
    @apply flex-1;
  }
  .nav-sidebar-links-link {
    @apply pb-2;
    @apply type-subtitle text-secondary-text;

    .nav-sidebar-links-disabled {
      @apply text-tertiary-text cursor-default;
      pointer-events: none;

      &:hover {
        @apply text-tertiary-text bg-transparent !important;
      }
    }

    .nav-sidebar-links-active {
      @apply relative;
      @apply text-orange-button-pressed;

      &:before {
        @apply ml-0 bg-orange-button-pressed !important;
      }
    }

    a,
    button,
    .nav-sidebar-links-disabled {
      @apply block truncate no-underline type-subtitle;
      @apply py-4 pl-8 pr-4;
      @apply transition-colors duration-180;

      &:hover {
        @apply text-primary-text;
      }

      &:before {
        content: '';
        @apply absolute bottom-0 left-0 top-0;
        @apply h-14 w-1;
        @apply bg-orange rounded-r-md;
        @apply transition-all duration-270;
        @apply -ml-1;
      }

      &.router-link-active {
        @apply relative;
        @apply text-orange !important;

        &:before {
          @apply ml-0;
        }
      }

      &.link {
        @apply font-normal text-gray;
      }
    }

    button {
      @apply w-full text-left outline-none;
    }

    .sub-item {
      @apply py-4 pl-13 pr-4;
    }

    /deep/ .md-ripple-wave {
      @apply bg-gray-light;
    }
  }
  .router-link-disabled {
    @apply pointer-events-none text-tertiary-text;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .nav-sidebar {
    min-height: -webkit-calc(100% - 20px);
    height: 100%;
  }
}
</style>
