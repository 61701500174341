<template>
  <div class="nav-mobile">
    <!-- Main menu -->
    <nav class="nav-mobile-menu">
      <!-- @slot: left -->
      <slot name="left"></slot>

      <!-- Hamburguer -->
      <button
        class="nav-mobile-icon-button"
        :class="{
          active: isActive,
          deactivated: !isActive,
        }"
        @click="isActive = !isActive"
      >
        <AppIcon class="nav-mobile-icon" size="24" name="Menu">
          <IconClose v-if="isActive" class="nav-mobile-icon-active" />
          <IconMenu v-else class="nav-mobile-icon-deactivated" />
        </AppIcon>
      </button>
    </nav>

    <!-- After clicking menu -->
    <main class="nav-mobile-content" :class="isActive ? 'nav-mobile-content-show' : null">
      <div class="flex-1">
        <!-- Main menu -->
        <nav class="nav-mobile-menu">
          <!-- @slot: left -->
          <slot name="left"></slot>
          <!-- Hamburguer -->
          <button
            class="nav-mobile-icon-button"
            :class="{
              active: isActive,
              deactivated: !isActive,
            }"
            @click="isActive = !isActive"
          >
            <AppIcon class="nav-mobile-icon" size="24" name="Menu">
              <Transition duration="1000" name="fade">
                <IconClose v-if="isActive" class="nav-mobile-icon-active" />
                <IconMenu v-else class="nav-mobile-icon-deactivated" />
              </Transition>
            </AppIcon>
          </button>
        </nav>

        <!-- Menu list -->
        <Transition duration="1000" name="nested">
          <ul v-show="isActive" class="nav-mobile-links">
            <li v-for="(item, idx) in items" :key="idx" class="nav-mobile-links-link inner">
              <!-- Render item without tooltip -->
              <AppRipple v-if="!item.tooltipOptions">
                <!-- A with href link or click handler -->
                <AppLink
                  v-if="item.click || item.href"
                  class="nav-mobile-link"
                  :href="item.href || null"
                  :target="item.target || null"
                  v-on="item.click ? { click: item.click } : {}"
                >
                  {{ item.name }}
                  <AppIcon v-if="item.icon" :size="18" class="ml-1 -m-1">
                    <Component :is="item.icon" />
                  </AppIcon>
                </AppLink>
                <!-- Disabled Links -->
                <p
                  v-else-if="item.isDisabled"
                  class="nav-mobile-links-disabled"
                  :class="item.isActive ? 'nav-mobile-links-active' : null"
                  @click="disabledItem"
                >
                  {{ item.name }}
                </p>
                <!-- Vue router link  -->
                <RouterLink
                  v-else-if="item.path"
                  v-slot="{ isActive, isExactActive }"
                  custom
                  :to="item.path"
                >
                  <AppLink
                    :class="[
                      isActive && 'router-link-active',
                      isExactActive && 'router-link-exact-active',
                    ]"
                    class="nav-mobile-link"
                    @click="goToTargetRoute(item.path.name)"
                  >
                    {{ item.name }}
                  </AppLink>
                </RouterLink>
              </AppRipple>

              <!-- Render item with tooltip -->
              <AppRipple v-if="item.tooltipOptions" :is-disabled="item.isDisabled">
                <VDropdown
                  container=".nav-mobile-content"
                  popper-class="nav-mobile-tooltip"
                  handle-resize
                  placement="top"
                  theme="tooltip"
                  :triggers="['click']"
                  auto-hide
                >
                  <!-- A with href link or click handler -->
                  <AppLink
                    v-if="item.click || item.href"
                    class="nav-mobile-link"
                    :href="item.href || null"
                    :target="item.target || null"
                    v-on="item.click ? { click: item.click } : {}"
                  >
                    {{ item.name }}
                    <AppIcon v-if="item.icon" :size="18" class="ml-1 -m-1">
                      <Component :is="item.icon" />
                    </AppIcon>
                  </AppLink>
                  <!-- Disabled Links -->
                  <p
                    v-else-if="item.isDisabled"
                    class="nav-mobile-links-disabled"
                    @click="disabledItem"
                  >
                    {{ item.name }}
                  </p>
                  <!-- Vue router link  -->
                  <RouterLink
                    v-else-if="item.path"
                    v-slot="{ isActive, isExactActive }"
                    custom
                    :to="item.path"
                  >
                    <AppLink
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      class="nav-mobile-link"
                      @click="goToTargetRoute(item.path.name)"
                    >
                      {{ item.name }}
                    </AppLink>
                  </RouterLink>

                  <!-- Data  -->
                  <template #popper>
                    {{ getTooltipContent(item.tooltipOptions()) }}
                  </template>
                </VDropdown>
              </AppRipple>

              <!-- Render subItem if exists -->
              <div v-for="(subItem, subIdx) in item.subItems" :key="subIdx">
                <!-- without tooltip -->
                <AppRipple v-if="!item.tooltipOptions">
                  <!-- A with href link or click handler -->
                  <AppLink
                    v-if="subItem.click || subItem.href"
                    class="nav-mobile-link"
                    :href="subItem.href || null"
                    :target="subItem.target || null"
                    v-on="subItem.click ? { click: subItem.click } : {}"
                  >
                    {{ subItem.name }}
                    <AppIcon v-if="item.icon" :size="18" class="ml-1 -m-1">
                      <Component :is="item.icon" />
                    </AppIcon>
                  </AppLink>
                  <!-- Disabled Links -->
                  <p
                    v-else-if="subItem.isDisabled"
                    class="nav-mobile-links-disabled"
                    @click="disabledItem"
                  >
                    {{ item.name }}
                  </p>
                  <!-- Vue router link  -->
                  <RouterLink
                    v-else-if="subItem.path"
                    v-slot="{ isActive, isExactActive }"
                    :to="subItem.path"
                    custom
                    class="sub-item"
                    :class="{ 'router-link-disabled': subItem.isDisabled }"
                  >
                    <AppLink
                      :class="[
                        isActive && 'router-link-active',
                        isExactActive && 'router-link-exact-active',
                      ]"
                      @click="goToTargetRoute(subItem.path.name)"
                    >
                      {{ subItem.name }}
                    </AppLink>
                  </RouterLink>
                </AppRipple>

                <!-- with tooltip -->
                <AppRipple v-if="item.tooltipOptions" :is-disabled="subItem.isDisabled">
                  <VDropdown
                    container=".nav-mobile-content"
                    popper-class="nav-mobile-tooltip"
                    handle-resize
                    placement="top"
                    theme="tooltip"
                    :triggers="['click']"
                    auto-hide
                  >
                    <!-- A with href link or click handler -->
                    <AppLink
                      v-if="subItem.click || subItem.href"
                      class="nav-mobile-link"
                      :href="subItem.href || null"
                      :target="subItem.target || null"
                      v-on="subItem.click ? { click: subItem.click } : {}"
                    >
                      {{ subItem.name }}
                      <AppIcon v-if="item.icon" :size="18" class="ml-1 -m-1">
                        <Component :is="item.icon" />
                      </AppIcon>
                    </AppLink>
                    <!-- Disabled Links -->
                    <p
                      v-else-if="subItem.isDisabled"
                      class="nav-mobile-links-disabled"
                      @click="disabledItem"
                    >
                      {{ item.name }}
                    </p>
                    <!-- Vue router link  -->
                    <RouterLink
                      v-else-if="subItem.path"
                      v-slot="{ isActive, isExactActive }"
                      :to="subItem.path"
                      custom
                      class="sub-item"
                      :class="{ 'router-link-disabled': subItem.isDisabled }"
                    >
                      <AppLink
                        :class="[
                          isActive && 'router-link-active',
                          isExactActive && 'router-link-exact-active',
                        ]"
                        @click="goToTargetRoute(subItem.path.name)"
                      >
                        {{ subItem.name }}
                      </AppLink>
                    </RouterLink>

                    <!-- Data  -->
                    <template #popper>
                      {{ getTooltipContent(item.tooltipOptions()) }}
                    </template>
                  </VDropdown>
                </AppRipple>
              </div>
            </li>
          </ul>
        </Transition>
      </div>
      <footer class="nav-mobile-footer">
        <!-- @slot footer: For any bottom content such as a Call to Action -->
        <div>
          <slot name="footer"></slot>
        </div>
      </footer>
    </main>
  </div>
</template>

<script>
import AppButton from '@/components/AppButton/AppButton'
import AppIcon from '@/components/AppIcon/AppIcon'
import AppLink from '@en-ui/components/AppLink/AppLink'
import AppNavBar from '@/components/AppNavBar/AppNavBar'
import { IconClose, IconMenu, IconLaunch } from '@moneytransfer.ui/euronet-icons'
import { useRouter, checkCurrentRouteAndRedirect } from '@/composables/useRouter'
import { ref } from 'vue-demi'

export default {
  name: 'NavMobile',
  components: {
    AppButton,
    AppIcon,
    AppLink,
    AppNavBar,
    IconClose,
    IconMenu,
    IconLaunch,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    theme: {
      type: String,
      required: true,
      default: 'default',
    },
  },
  setup(props, { emit }) {
    const router = useRouter()
    const goToTargetRoute = (nameRoute) => checkCurrentRouteAndRedirect(router, nameRoute)
    const isActive = ref(false)

    // Get tooltip content
    const getTooltipContent = (obj) => {
      if (obj) {
        return obj.content.replace(/<[^>]*>?/gm, '').replace(/,/g, ', ')
      }
    }

    // Disabled items
    const disabledItem = () => {
      emit('disabled-item')
      isActive.value = false
    }

    return {
      isActive,
      disabledItem,
      goToTargetRoute,
      getTooltipContent,
    }
  },
}
</script>

<style lang="postcss" scoped>
.nav-mobile {
  @apply sticky top-0 bg-white z-100;

  &-menu {
    padding-top: 0.844rem;
    padding-bottom: 0.844rem;
    @apply flex justify-between px-4 shadow-ria-1;
  }

  &-content {
    @apply flex flex-col justify-start fixed w-full top-0;
    @apply bg-white;
    @apply overflow-y-auto;
    height: 100%;
    z-index: 9999;
    transform: translateX(120%);
    transition: transform 0.3s ease-out;

    &-show {
      transform: translateX(0%);
      transition: transform 0.3s ease-in;
    }
  }

  /deep/ &-tooltip {
    .v-popper__wrapper {
      @apply mx-2;
    }
  }

  &-footer {
    @apply w-full;
    @apply sticky bottom-0 bg-white z-100;

    & button {
      @apply w-full text-tertiary-text p-1;
    }
  }

  &-icon {
    @apply text-gray-lighter -mb-0.5;
    transition: all 0.1s ease-in-out;

    &-button {
      @apply flex items-center p-0;

      &:focus {
        @apply outline-none;
      }

      &:hover {
        @apply bg-none bg-transparent;
      }
    }
  }

  &-link {
    @apply w-full text-left;

    &:active {
      @apply text-secondary-text;
    }

    &:hover {
      @apply no-underline;
    }

    &:focus {
      @apply outline-none;
    }
  }

  &-links {
    &-link {
      @apply mb-2 bg-white opacity-100;
      @apply type-subtitle text-tertiary-text list-none;
    }

    &-link:first-of-type {
      @apply pt-5;
    }

    &-disabled {
      @apply text-tertiary-text cursor-default;

      &:hover {
        @apply text-tertiary-text bg-gray-bg !important;
      }
    }
  }
}

a.nav-mobile-link,
button.nav-mobile-link,
.nav-mobile-links-disabled {
  @apply block truncate no-underline pointer-events-none type-body;
  @apply py-4 pl-8 pr-4;
  @apply transition-colors duration-180;

  &:hover {
    @apply text-primary-text bg-none no-underline;
  }

  &:before {
    content: '';
    @apply absolute bottom-0 left-0 top-0;
    @apply h-14 w-1;
    @apply bg-orange rounded-r-md;
    @apply transition-all duration-270;
    @apply -ml-1;
  }

  &.router-link-active {
    @apply relative;
    @apply text-orange !important;

    &:before {
      @apply ml-0;
    }
  }

  &.link {
    @apply font-normal text-gray;
  }
}
.nav-mobile-links-active {
  @apply relative;
  @apply text-orange-button-pressed;

  &:before {
    @apply ml-0 bg-orange-button-pressed !important;
  }
}

/* Animations */
.nested {
  &-leave {
    &-active {
      transition: all 0.2s ease;
    }

    &-to {
      opacity: 0;
    }
  }

  &-enter {
    &-active {
      transition: all 0.6s ease-in;
    }
  }
}

.fade {
  &-active {
    transition: all 0.1s ease-in-out;
  }
}

/* Keyframes */
@keyframes fade-in {
  0% {
    @apply opacity-0;
  }
  90% {
    @apply opacity-70;
  }
  100% {
    @apply opacity-100;
  }
}
</style>
