<template>
  <div>
    <!-- Sidebar Stepper -->
    <aside class="stepper">
      <!-- Top -->
      <header class="stepper-top">
        <button class="w-full">
          <AppRipple>
            <div
              class="stepper-top-wrapper"
              @keyup.enter="goTo(content.top.routeTo)"
              @click.stop="goTo(content.top.routeTo)"
            >
              <!-- Icon -->
              <AppIcon class="stepper-top-icon">
                <Component :is="content.top.icon"></Component>
              </AppIcon>
              <!-- Title -->
              <h2 class="stepper-top-title">{{ content.top.title }}</h2>
            </div>
          </AppRipple>
        </button>
      </header>
      <!-- Content -->
      <section v-if="!hideContent" class="stepper-content" :class="[hideAccordionClass]">
        <div class="stepper-content-collapse-button-container">
          <button class="stepper-content-collapse-button" @click.stop="collapseButtonStatus">
            <AppIcon>
              <Component :is="collapseButtonIcon" />
            </AppIcon>
            <span>{{ collapseButtonText }}</span>
          </button>
        </div>

        <ul class="stepper-content-list">
          <li
            v-for="item in stepperItems"
            :key="item.id"
            class="stepper-content-list-item"
            :class="[
              activeItemClass(item.activeItem),
              hasDescriptionClass(Boolean(item.description)),
              isClickableClass,
            ]"
            @click.stop="$emit('selected-item', item.id)"
            @keyup.enter="$emit('selected-item', item.id)"
          >
            <Component
              :is="options.isClickable ? 'AppRipple' : 'Div'"
              class="stepper-content-list-wrapper"
            >
              <div class="stepper-content-list-item-container">
                <div class="stepper-content-list-item-icon-wrapper">
                  <i
                    class="stepper-content-list-item-icon"
                    :class="[isItemCheckedClass(item.isItemChecked)]"
                  >
                    <AppIcon v-if="item.isItemChecked" color="white">
                      <IconCheckmark />
                    </AppIcon>
                  </i>
                </div>
                <div class="stepper-content-list-item-line"></div>
              </div>
              <div class="stepper-content-list-item-caption">
                <span class="stepper-content-list-item-name">{{ item.name }}</span>
                <span v-if="item.description" class="stepper-content-list-item-description">
                  {{ item.description }}
                </span>
              </div>
            </Component>
          </li>
        </ul>
      </section>
    </aside>
  </div>
</template>

<script>
import { useRouter } from '@/composables/useRouter'
import { useMediaQuery } from '@/composables/useMediaQuery'
import { reactive, computed, ref, watch } from '@vue/composition-api'

import AppIcon from '@en-ui/components/AppIcon/AppIcon'
import {
  IconChevronLeft,
  IconCheckmark,
  IconAddAlt,
  IconSubtractAlt,
} from '@moneytransfer.ui/euronet-icons'

export default {
  components: {
    AppIcon,
    IconChevronLeft,
    IconCheckmark,
    IconAddAlt,
    IconSubtractAlt,
  },
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    hideContent: {
      type: Boolean,
      default: false,
    },
    hideAccordion: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter()
    const mq = reactive(useMediaQuery())
    const { content } = props.options
    const isCollapsed = ref(true)

    mq.current === 'xs' ? (isCollapsed.value = true) : (isCollapsed.value = false)

    const collapseButtonIcon = computed(() =>
      isCollapsed.value ? 'IconAddAlt' : 'IconSubtractAlt'
    )
    const collapseButtonText = computed(() =>
      isCollapsed.value ? 'Show completed steps' : 'Hide completed steps'
    )
    const stepperItems = computed(() => (isCollapsed.value ? setCollapsedItems() : props.items))

    const activeItemClass = (isActive) => {
      if (isCollapsed.value) {
        return isActive
          ? 'stepper-content-list-item-active stepper-content-list-item-active-collapsed'
          : null
      }
      return isActive ? 'stepper-content-list-item-active' : null
    }
    const isItemCheckedClass = (isChecked) => {
      return isChecked
        ? `stepper-content-list-item-icon-active stepper-content-list-item-icon-active-${
            props.options.checkedColor || 'green'
          }`
        : null
    }
    const hasDescriptionClass = (hasDescription) => {
      return hasDescription ? null : 'stepper-content-list-item-no-description'
    }
    const isClickableClass = () => {
      return props.options.isClickable ? 'cursor-pointer' : 'cursor-auto'
    }
    const goTo = (route) => router.push(route)
    const collapseButtonStatus = () => (isCollapsed.value = !isCollapsed.value)
    const setCollapsedItems = () => {
      let items = props.items
      items.forEach((item, i) => {
        if (mq.current === 'xs' && item.activeItem) {
          return items.length === i + 1
            ? (items = [items[i - 1], item])
            : (items = [item, items[i + 1]])
        }
      })
      return items
    }

    watch(mq, () => {
      mq.current === 'xs' ? (isCollapsed.value = true) : (isCollapsed.value = false)
    })

    const hideAccordionClass = computed(() =>
      props.hideAccordion ? 'stepper-content-hide-accordion' : null
    )

    return {
      mq,
      content,
      isCollapsed,
      collapseButtonText,
      collapseButtonIcon,
      stepperItems,
      activeItemClass,
      isItemCheckedClass,
      hasDescriptionClass,
      isClickableClass,
      goTo,
      collapseButtonStatus,
      hideAccordionClass,
    }
  },
}
</script>

<style lang="postcss" scoped>
.stepper {
  @apply border-r border-gray-lighter overflow-hidden;
  @apply flex flex-col justify-between mt-4 px-2 pt-0 pb-6;

  @screen sm {
    @apply w-52 pl-0 mt-0 pr-0 fixed min-h-full h-screen;
    @apply bg-tertiary-gray-darker;
  }

  @screen md {
    @apply w-80 left-0 pl-4;
  }

  &:hover {
    @apply overflow-y-hidden;
  }

  &-top {
    @apply hidden;

    @screen sm {
      @apply block;
    }

    &-wrapper {
      @apply flex items-center -ml-1 pl-4  pt-8 pb-6 cursor-pointer;
    }

    &-icon {
      @apply text-gray !important;
    }

    &-title {
      @apply ml-1 type-h3;
    }
  }

  &-content {
    @apply type-caption text-gray border;
    @apply border-tertiary-grey-darkest shadow-ria-1 rounded-2xl;
    @apply py-4 pb-4;

    @screen sm {
      @apply h-full shadow-none border-none pt-4 overflow-x-hidden overflow-y-auto;
    }

    &-hide-accordion {
      @apply hidden;

      @screen sm {
        @apply block;
      }
    }

    &-collapse-button {
      @apply flex items-center justify-center;
      @apply font-medium leading-4 text-xs text-main-cyan;

      &:focus {
        @apply outline-none;
      }

      &-container {
        @apply w-full flex justify-center mb-6;

        @screen sm {
          @apply hidden;
        }
      }

      .app-icon {
        @apply w-3 h-3 mr-1;
      }

      @screen sm {
        @apply hidden;
      }
    }

    &-list-wrapper {
      @apply flex pl-4 font-semibold relative pr-5;
    }

    &-list-item {
      @apply flex flex-col;

      &-no-description > .stepper-content-list-item-line {
        margin-top: 2px;
      }

      &:last-child {
        .stepper-content-list-item-caption {
          @apply pb-2;
        }

        &.stepper-content-list-item-active {
          .stepper-content-list-item-caption {
            @apply pb-0;
          }

          &::before {
            @apply hidden;
          }
        }

        .stepper-content-list-item-line,
        &::after {
          @apply hidden;
        }
      }

      &-active {
        @apply mt-0_5;

        &::before,
        &::after {
          content: '';
          @apply inline-block rounded-full ml-6 mt-0_5;
          background-color: rgba(207, 209, 212, 1);
          width: 2px;
        }

        &.stepper-content-list-item-active-collapsed {
          &::before {
            @apply h-2;

            @screen sm {
              @apply hidden;
            }
          }
        }

        &::after {
          @apply h-9;
        }

        &-collapsed {
          &:first-child > .stepper-content-list-item-line {
            height: 92px;
            margin-top: -55px;
          }
        }

        .stepper-content-list-item-caption,
        .stepper-content-list-item-icon-wrapper {
          @apply pb-0;
        }

        .stepper-content-list-item-caption {
          @apply pb-0;
        }

        .stepper-content-list-wrapper {
          @apply w-11/12 rounded-xl py-3 pr-5 items-center;
          @apply type-caption font-semibold bg-gray-active text-main-black;
          margin-left: 0.375rem;
          padding-left: 0.688rem;

          @screen sm {
            @apply py-4;
            width: 12.5rem;
          }

          @screen md {
            width: 18.5rem;
          }
        }
        .stepper-content-list-item-name {
          @apply type-caption font-semibold;
        }
        .stepper-content-list-item-icon {
          @apply mt-0 border-main-black;
        }

        .stepper-content-list-item-description {
          @apply hidden;
        }

        .stepper-content-list-item-description,
        .stepper-content-list-item-icon-active {
          @apply bg-transparent;

          .app-icon {
            @apply hidden;
          }
        }
      }

      &-line {
        @apply inline-block rounded-full;
        background-color: rgba(207, 209, 212, 1);
        height: 100%;
        width: 2px;

        &:first-child {
          display: none;
        }
      }

      &-icon {
        @apply inline-block relative border-gray border rounded-full;
        margin-top: 2px;
        min-width: 18px;
        height: 18px;

        &-active {
          @apply flex justify-center items-center;

          & > svg {
            width: 14px;
            height: 14px;
            position: absolute;
          }

          &-primary {
            @apply border-main-cyan bg-main-cyan;
          }

          &-green {
            border-color: rgba(8, 135, 62, 1);
            background-color: rgba(8, 135, 62, 1);
          }
        }

        &-wrapper {
          @apply flex pb-0_5;
        }
      }

      &-container {
        @apply flex flex-col items-center;
      }

      &-name {
        @apply pr-2;

        @screen sm {
          @apply pr-0;
        }
      }

      &-caption {
        @apply flex flex-col pl-2 pb-8;
      }

      &-description {
        @apply type-caption-xs pt-1 pr-6;
        @apply break-words;
        overflow-wrap: anywhere;
        hyphens: auto;
        color: rgba(165, 170, 181, 1);
      }
    }
  }
}
</style>
