var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"nav-sidebar",attrs:{"aria-label":"Main"}},[_c('div',[_c('header',[_vm._t("header")],2),_c('ul',{staticClass:"nav-sidebar-links"},[_vm._t("items",function(){return _vm._l((_vm.items),function(item,idx){return _c('li',{directives:[{name:"tooltip",rawName:"v-tooltip.top-start",value:(item.tooltipOptions ? item.tooltipOptions() : {}),expression:"item.tooltipOptions ? item.tooltipOptions() : {}",modifiers:{"top-start":true}}],key:idx,staticClass:"nav-sidebar-links-link"},[_c('AppRipple',{attrs:{"is-disabled":item.isDisabled}},[(item.click || item.href)?_c('AppLink',_vm._g({staticClass:"link",attrs:{"href":item.href || null,"target":item.target || null}},item.click ? { click: item.click } : {}),[_vm._v(" "+_vm._s(item.name)+" "),(item.icon)?_c('AppIcon',{staticClass:"ml-1 -m-1",attrs:{"size":18}},[_c(item.icon,{tag:"Component"})],1):_vm._e()],1):(item.isDisabled)?_c('p',{staticClass:"nav-sidebar-links-disabled",class:item.isActive ? 'nav-sidebar-links-active' : null,on:{"click":function($event){return _vm.$emit('disabled-item')}}},[_vm._v(" "+_vm._s(item.name)+" ")]):(item.path)?_c('RouterLink',{attrs:{"custom":"","to":item.path},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('AppLink',{class:[
                  isActive && 'router-link-active',
                  isExactActive && 'router-link-exact-active' ],on:{"click":function($event){return _vm.goToTargetRoute(item.path.name)}}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)}):_vm._e()],1),_vm._l((item.subItems),function(subItem,subIdx){return _c('AppRipple',{key:subIdx,attrs:{"is-disabled":subItem.isDisabled}},[(subItem.click || subItem.href)?_c('AppLink',_vm._g({staticClass:"link",attrs:{"href":subItem.href || null,"target":subItem.target || null}},subItem.click ? { click: subItem.click } : {}),[_vm._v(" "+_vm._s(subItem.name)+" "),(item.icon)?_c('AppIcon',{staticClass:"ml-1 -m-1",attrs:{"size":18}},[_c(item.icon,{tag:"Component"})],1):_vm._e()],1):(subItem.isDisabled)?_c('p',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(subItem.tooltipOptions ? subItem.tooltipOptions() : {}),expression:"subItem.tooltipOptions ? subItem.tooltipOptions() : {}"}],staticClass:"nav-sidebar-links-disabled",on:{"click":function($event){return _vm.$emit('disabled-item')}}},[_vm._v(" "+_vm._s(item.name)+" ")]):(subItem.path)?_c('RouterLink',{staticClass:"sub-item",class:{ 'router-link-disabled': subItem.isDisabled },attrs:{"to":subItem.path,"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('AppLink',{class:[
                  isActive && 'router-link-active',
                  isExactActive && 'router-link-exact-active' ],on:{"click":function($event){return _vm.goToTargetRoute(subItem.path.name)}}},[_vm._v(" "+_vm._s(subItem.name)+" ")])]}}],null,true)}):_vm._e()],1)})],2)})})],2)]),_c('footer',[_vm._t("footer")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }