<template>
  <div class="flex flex-col relative" :class="[pendingActionsClass]">
    <!-- Navigation -->
    <DashboardNavigation
      :mq="mq"
      :navigation-config="pendingActionsConfig.navigation"
      :current-route="currentRoute"
      :is-pending-actions-route="isPendingActionsRoute"
      :is-navbar-activated="isNavbarActivated"
    />
    <!-- Display Child Route Form -->
    <div :class="[routerViewClass, StpClass]" class="pending-actions-router-view">
      <RouterView />
    </div>
    <XeHighValueModal />
  </div>
</template>

<script>
import { computed, reactive, ref, watch, onMounted } from '@vue/composition-api'
import { useMediaQuery } from '@/composables/useMediaQuery'
import { useRouter } from '@/composables/useRouter'
import useSessionTimeout from '@/composables/useSessionTimeout/useSessionTimeout'

import DashboardNavigation from './DashboardNavigation.vue'
import XeHighValueModal from '@/components/XeHighValueModal/XeHighValueModal.vue'
import { useDashboardStore } from '@/stores/dashboard'

import { useAuthStore } from '../../stores/auth'
import { useAppStore } from '@/stores/app'

import { useAnalyticsStore } from '@/stores/analytics'

export default {
  components: {
    DashboardNavigation,
    XeHighValueModal,
  },
  setup() {
    /*
     * Global settings for pending actions layout
     */
    const router = useRouter()
    const dashboardStore = useDashboardStore()
    const authStore = useAuthStore()
    const appStore = useAppStore()
    const analyticsStore = useAnalyticsStore()

    // Default value
    const mq = reactive(useMediaQuery())
    const pendingActionsConfig = computed(() => dashboardStore.pendingActionsConfig)
    const isStpAccount = computed(() => dashboardStore.getAccountIsAllowedForStp)

    // Check navigation logic to render navbar components (Authorized Personnel & Additional Info only)
    const currentRoute = ref(router.currentRoute.name)
    const isPendingActionsRoute = ref(currentRoute.value === 'PendingActions')
    const isNavbarActivated = ref(
      currentRoute.value === 'AuthorizedPersonnel' || currentRoute.value === 'AdditionalInfo'
    )
    router.afterEach((to) => {
      currentRoute.value = to.name
      isPendingActionsRoute.value = false
      isNavbarActivated.value = false

      if (to.name === 'PendingActions') {
        isPendingActionsRoute.value = true
      } else if (to.name === 'AuthorizedPersonnel' || to.name === 'AdditionalInfo') {
        isNavbarActivated.value = true
      }
    })

    // Set current route
    dashboardStore.currentRoute = currentRoute.value
    watch(currentRoute, () => (dashboardStore.currentRoute = currentRoute.value))

    // Set pending actions config
    dashboardStore.setPendingActionsConfig()
    dashboardStore.setMockedCompanyDetails()

    // Dashboard Layout Setup
    onMounted(async () => {
      // Check
      const loggedInPromise = authStore.checkGso()

      // Route to "create account" page only for first time accounts
      const isFirstLoad = dashboardStore.isFirstLoad
      if (isFirstLoad) {
        dashboardStore.isFirstLoad = false
        router.push({ name: 'AccountCreated' })
      }

      // Send GTM variable (Zendesk ticket) - Add contextual info about email and country to display Zendesk widget
      analyticsStore.gtmTrack({
        event: 'Registration_Email',
        variables: {
          email: dashboardStore.corpProfile?.mainContact?.email || '',
        },
      })
      analyticsStore.gtmTrack({
        event: 'Registration_Country',
        variables: {
          Registration_Country: dashboardStore.corpProfile?.registrationAddress?.country || '',
        },
      })

      const loggedIn = await loggedInPromise

      // Passed GSO
      if (loggedIn) {
        // Set timeout to logout user after 15 mins of inactivity
        useSessionTimeout()
        // Initialize stepper items
        if (dashboardStore.getStepperInitializeState) {
          await dashboardStore.setInitializeSteps()
        }
        if (dashboardStore.corpProfile.companyDetails.registrationNumber) {
          await dashboardStore.getBvdData()
        }
        // disable global loader
        appStore.setLoadedState(loggedIn)
      }
    })

    const isStepperActivated = computed(() => {
      const pageConfig = dashboardStore.getStpPageById(currentRoute.value)
      if (pageConfig) {
        return pageConfig.isStepper
      }
      return false
    })

    // Style class
    const pendingActionsClass = computed(() => {
      return mq.current !== 'xs' ? 'dashboard-sidebar' : 'h-full'
    })
    const routerViewClass = computed(() => {
      if (isStepperActivated.value) {
        return mq.current !== 'xs' ? 'mx-4 md:pl-80 sm:pl-52 h-full' : 'h-fit'
      }
      return mq.current !== 'xs' ? 'mx-4 pl-64 h-full' : 'h-fit'
    })
    const StpClass = computed(() => {
      return isStpAccount.value ? 'pending-actions-adjust-height pending-actions-stp' : null
    })

    return {
      mq,
      pendingActionsConfig,
      pendingActionsClass,
      routerViewClass,
      isStepperActivated,
      currentRoute,
      isPendingActionsRoute,
      isNavbarActivated,
      StpClass,
    }
  },
}
</script>

<style lang="postcss">
.pending-actions-router-view {
  @apply w-full h-full flex justify-center;

  @screen sm {
    @apply block py-12;
  }
}

.pending-actions-adjust-height {
  @screen sm {
    @apply mx-0 pl-56 mr-4;
  }

  @screen md {
    padding-left: 21rem;
  }
}

.dashboard-sidebar {
  @apply flex relative;
  @apply bg-tertiary-gray-darker;
  @screen sm {
    @apply min-h-full;
  }
}

.button {
  @apply type-subtitle rounded-none !important;

  @screen sm {
    @apply rounded-lg !important;
  }
}

.button {
  &.button--primary {
    &:disabled:not(.button--loading) {
      @apply text-white bg-blue-button-disabled !important;
    }
  }
}
/* Card Styles */
.card {
  border: none;
}

/* Card content styles */
.card-content {
  @apply px-4 pb-12 !important;

  @screen sm {
    @apply px-12 !important;
  }

  .card-content-block {
    @apply mb-0 !important;
  }
}

.pending-actions-stp {
  .card-footer {
    @apply pt-0 pb-4 px-4 shadow-none !important;

    @screen sm {
      @apply pt-0 pr-0 !important;
    }
  }

  .button {
    border-radius: 0.5rem !important;

    @screen sm {
      width: inherit !important;
    }
  }
}
</style>
