<template>
  <nav class="nav-sidebar" aria-label="Main">
    <header>
      <!-- @slot header: For any top content such as a Logo -->
      <slot name="header"> </slot>
    </header>
    <ul class="nav-sidebar-links">
      <!-- @slot items: For templating the sidebar items-->
      <slot name="items">
        <li v-for="(item, idx) in items" :key="idx" class="nav-sidebar-links-link">
          <AppRipple>
            <!-- A with href link or click handler -->
            <AppLink
              v-if="item.click || item.href"
              v-on="item.click ? { click: item.click } : {}"
              :href="item.href || null"
              :target="item.target || null"
              :analytics-name="getAnalyticsName(item)"
            >
              {{ item.text }}
              <AppIcon v-if="item.icon" :size="24">
                <Component :is="item.icon" />
              </AppIcon>
            </AppLink>
            <!-- Vue router link  -->
            <RouterLink
              v-else-if="item.route"
              :to="item.route"
              :analytics-name="getAnalyticsName(item)"
            >
              {{ item.text }}
              <AppIcon v-if="item.icon" :size="24">
                <Component :is="item.icon" />
              </AppIcon>
            </RouterLink>
          </AppRipple>
        </li>
      </slot>
    </ul>
    <footer>
      <!-- @slot footer: For any bottom content such as a Call to Action -->
      <slot name="footer"> </slot>
    </footer>
  </nav>
</template>
<script>
import AppLink from '@en-ui/components/AppLink/AppLink'
import AppIcon from '@en-ui/components/AppIcon/AppIcon'
export default {
  name: 'AppNavSidebar',
  components: {
    AppLink,
    AppIcon,
  },
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
      validator: (items) => {
        return items.find((item) => {
          // Each item must have an id and route/click/href
          return item.id && (item.route || item.click || item.href)
        })
      },
    },
  },
  setup() {
    const getAnalyticsName = (item) => {
      return 'navsidebar-' + item.id
    }
    return {
      getAnalyticsName,
    }
  },
}
</script>

<style scoped>
.nav-sidebar {
  &::-webkit-scrollbar-thumb {
    @apply border-gray-bg;
  }
  @apply w-64 h-full;
  @apply bg-gray-bg border-r border-gray-lighter;
  @apply flex flex-col;
  @apply overflow-y-auto;
  .app-icon {
    @apply ml-2.5;
    @apply align-middle;
  }
  .nav-sidebar-links {
    @apply flex-1;
  }
  .nav-sidebar-links-link {
    @apply mb-2;

    a,
    button {
      @apply text-left w-full;
      @apply type-subtitle font-bold text-secondary-text;
      @apply block truncate;
      @apply py-4 pl-8 pr-4;
      @apply transition-colors duration-180;

      &:hover {
        @apply no-underline;
        @apply text-primary-text bg-gray-lighter;
      }

      &:before {
        content: '';
        @apply absolute bottom-0 left-0 top-0;
        @apply h-14 w-1;
        @apply bg-orange rounded-r-md;
        @apply transition-all duration-270;
        @apply -ml-1;
      }

      &.router-link-active {
        @apply relative;
        @apply text-orange;

        &:before {
          @apply ml-0;
        }
      }
    }

    ::v-deep .md-ripple-wave {
      @apply bg-gray-light;
      &.md-ripple-enter {
        @apply opacity-100;
      }
    }
  }
}
</style>
<style></style>
