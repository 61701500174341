<template>
  <nav
    class="navbar"
    aria-label="Main Navigation"
    :class="[stepperClass, isFixed && 'navbar-is-fixed', setBorderStyle]"
  >
    <div class="navbar-container">
      <div
        v-if="!hideBorder"
        class="navbar-progress-bar"
        :style="{ width: `${progressBar.percent}%` }"
      ></div>
      <div class="navbar-left">
        <!-- @slot left: For action buttons -->
        <slot name="left"></slot>
      </div>
      <div class="navbar-center">
        <!-- @slot default: Either a title or stepper goes here -->
        <slot></slot>
      </div>
      <div class="navbar-right">
        <!-- @slot right: For action buttons -->
        <slot name="right"></slot>
      </div>
    </div>
  </nav>
</template>

<script>
import { provide, computed, reactive } from '@vue/composition-api'

export default {
  props: {
    isFixed: {
      type: Boolean,
      default: false,
    },
    showProgressBar: {
      type: String,
      default: 'md',
    },
    hideBorder: {
      type: Boolean,
      default: false,
    },
    progressBarActive: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const progressBar = reactive({
      active: false,
      percent: 0,
    })
    provide('progressBar', progressBar)

    const stepperClass = computed(() => {
      if (props.progressBarActive || progressBar.active) {
        return `navbar--stepper-${props.showProgressBar}`
      }
    })
    const setBorderStyle = computed(() => {
      return props.hideBorder ? null : `navbar-border`
    })
    return {
      progressBar,
      stepperClass,
      setBorderStyle,
    }
  },
}
</script>

<style lang="postcss" scoped>
.navbar {
  @apply border-b box-content border-transparent;
  @apply transition-all duration-270 ease-in-out;

  @screen sm {
    @apply mb-8;
  }

  &-border {
    box-shadow: 0px 3px 15px rgba(0, 17, 51, 0.05);
    @apply border-gray-lighter;

    @screen sm {
      @apply shadow-none border-transparent;
    }
  }
  &.navbar--stepper {
    &-sm {
      @screen sm {
        .navbar-container {
          height: 96px;
        }
        .navbar-progress-bar {
          @apply hidden;
        }
      }
    }

    &-md {
      @screen md {
        .navbar-container {
          height: 96px;
        }
        .navbar-progress-bar {
          @apply hidden;
        }
      }
    }
  }
}
.navbar-container {
  @apply relative flex justify-center items-center;
  @apply h-14;
  @apply px-4;
  @screen sm {
    @apply h-16;
    @apply px-6;
  }
  @apply max-w-6xl mx-auto;
}

.icon {
  @apply flex justify-center items-center;
  @apply outline-none;

  /deep/ .button--icon {
    @apply w-12 h-12;
  }

  @screen sm {
    /deep/ .button--icon {
      @apply w-10 h-10;
    }
  }
}
.navbar-left,
.navbar-right {
  /*Make left and right grow to fill space, this makes the centered item true center*/
  @apply flex-1;
  @screen md {
    @apply flex-initial;
    min-width: 64px;
  }
}
.navbar-left {
  .icon {
    @apply -ml-3;
    @screen sm {
      @apply -ml-2;
    }
  }
}
.navbar-center {
  @apply truncate text-center;
  @apply mx-4;
  @apply type-h3;
  @screen md {
    @apply flex-1;
  }
}
.navbar-right {
  @apply flex justify-end;
  .icon {
    @apply -mr-3;
    @screen sm {
      @apply -mr-2;
    }
  }
}
.navbar-progress-bar {
  @apply absolute bottom-0 left-0;
  @apply bg-orange;
  @apply transition-all duration-270 ease-in-out;
  @apply -mb-px;
  height: 2px;
}
.navbar-is-fixed {
  @apply w-full fixed bg-white z-10;
}
</style>
