<template>
    <div class="z-50" :class="fixedClasses">
        <div class="bg-white border border-gray-light" :class="cardClasses">
            <slot />
        </div>
    </div>
  </template>
  
  <script>
  import { computed } from '@vue/composition-api'

  export const themes = ["bottom-left"]
  
  export default {
    name: 'XeModalBottom',
    props: {
      theme: {
        type: String,
        default: 'bottom-left',
        validator: (value) => {
          return themes.indexOf(value) > -1
        },
      },
    },
    setup(props) {
      const cardClasses = computed(() => {
        if (props.theme == "bottom-left") {
          return "card-bottom-left"
        }
        
        throw new Error('Theme not supported')
      })

      const fixedClasses = computed(() => {
        if (props.theme == "bottom-left") {
          return "fixed-bottom-left"
        }
        
        throw new Error('Theme not supported')
      })

      return {
        cardClasses,
        fixedClasses,
      }
    }
  }
  </script>

<style lang="postcss" scoped>
.card {
  @apply bg-white border border-gray-light
}
.card-bottom-left {
  @apply max-w-full rounded-t-xl;

  @screen sm {
    @apply max-w-sm rounded-xl pointer-events-auto;
  }
}

.fixed {
 @apply absolute z-50 
}
.fixed-bottom-left {
  @apply sticky bottom-0;

  @screen sm {
    @apply sticky flex justify-end bottom-0 right-0 p-8 pointer-events-none ;
  }
}
</style>

