import { render, staticRenderFns } from "./XeNavSidebar.vue?vue&type=template&id=d10551d8&scoped=true&"
import script from "./XeNavSidebar.vue?vue&type=script&lang=js&"
export * from "./XeNavSidebar.vue?vue&type=script&lang=js&"
import style0 from "./XeNavSidebar.vue?vue&type=style&index=0&id=d10551d8&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d10551d8",
  null
  
)

export default component.exports