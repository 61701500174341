<template>
  <XeSideModal v-if="showModal">
    <div class="sm:h-72 sm:w-96 flex flex-col items-center justify-center">
      <XeCardImageTitle
        v-if="requestedCall"
        :title="successTitle"
        src="illustrations/created.svg"
        alt="Call requested"
        class="h-full w-full"
        @close="toggleShowModal"
      >
        <div class="flex flex-col justify-center items-center text-center mt-4">
          <div class="text-gray">Our team will contact you in the next 1-3 hours</div>
          <div class="mt-4">
            <AppButton disabled="true"> Call requested </AppButton>
          </div>
        </div>
      </XeCardImageTitle>
      <XeCardImageTitle
        v-else
        title="Looking to make a payment today?"
        class="h-full w-full"
        @close="toggleShowModal"
      >
        <div class="flex flex-col justify-center items-center mt-4">
          <div class="text-center">Receive a call now to expedite your onboarding</div>
          <div class="mt-4">
            <AppButton @click="requestCallClicked"> Request a call </AppButton>
          </div>
          <div class="text-xs text-center mt-4 text-text-gray-secondary">
            To contact us directly, call
            <a
              href="#"
              class="cursor-pointer text-blue underline"
              @click.prevent.stop="onPhoneClick"
              >{{ onboardingPhoneNumber }}</a
            >
            (6AM-5PM PT)
          </div>
        </div>
      </XeCardImageTitle>
    </div>
  </XeSideModal>
</template>

<script>
import { computed, ref, watch } from '@vue/composition-api'
import { useAnalyticsStore } from '@/stores/analytics'
import { useDashboardStore } from '@/stores/dashboard'

import AppButton from '@/components/AppButton/AppButton'
import XeSideModal from '@/components/XeSideModal/XeSideModal.vue'
import XeCardImageTitle from '@/components/XeCardImageTitle/XeCardImageTitle.vue'
import { storeToRefs } from 'pinia'

const ALLOWED_TBUS = [
  'XEMT - US - Corporate - FX',
  'XEMT - CA - Corporate - FX',
  'XEMT - US - Corporate - FXPLUS',
  'XEMT - CA - Corporate - FXPLUS',
]
export default {
  name: 'XeHighValueModal',
  components: {
    AppButton,
    XeSideModal,
    XeCardImageTitle,
  },
  setup() {
    const analyticsStore = useAnalyticsStore()
    const dashboardStore = useDashboardStore()
    const { displayHVModal, getIsHighValueClient, getPhoneNumber, getClientNumber, getTbuName } =
      storeToRefs(dashboardStore)

    const showModal = ref(
      getIsHighValueClient.value && ALLOWED_TBUS.includes(getTbuName.value) && displayHVModal.value
    )
    const requestedCall = ref(false)

    watch(displayHVModal, (newValue) => {
      if (!newValue) {
        showModal.value = false
      }
    })

    const toggleShowModal = () => {
      showModal.value = false
      dashboardStore.displayHVModal = false
    }

    const requestCallClicked = () => {
      analyticsStore.track({
        event: 'Call Requested',
        traits: {
          xeNumber: getClientNumber.value,
          phoneNumber: getPhoneNumber.value,
          tbuName: getTbuName.value,
        },
      })
      requestedCall.value = true
      setTimeout(toggleShowModal, 7000)
    }

    const anonymizedPhoneNumber = computed(() => {
      if (!getPhoneNumber.value || getPhoneNumber.value.length <= 3) {
        return undefined
      }
      const lastThreeDigits = getPhoneNumber.value.slice(-3)
      const anonymizedNumber = 'X'.repeat(getPhoneNumber.value.length - 3) + lastThreeDigits

      return anonymizedNumber
    })

    const successTitle = computed(() => {
      if (anonymizedPhoneNumber.value) {
        return `Expect a call to ${anonymizedPhoneNumber.value}`
      }

      return 'Expect a call to your registration number'
    })

    const onboardingPhoneNumber = computed(() => '+17372482072')

    const onPhoneClick = () => {
      window.location.href = `tel:${onboardingPhoneNumber.value}`
    }

    return {
      showModal,
      toggleShowModal,
      requestedCall,
      requestCallClicked,
      successTitle,
      onboardingPhoneNumber,
      onPhoneClick,
    }
  },
}
</script>
