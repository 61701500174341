import { useAuthStore } from '../../stores/auth'

export default () => {
  const authStore = useAuthStore()

  let debounceTimer

  const debounce = (callback, time) => {
    window.clearTimeout(debounceTimer)
    debounceTimer = window.setTimeout(callback, time)
  }

  const logout = async () => await authStore.logout()

  const handleEvent = () => debounce(logout, 15 * 60 * 1000)

  window.addEventListener('mousemove', handleEvent)
  window.addEventListener('scroll', handleEvent)
  window.addEventListener('keydown', handleEvent)
  window.addEventListener('click', handleEvent)

  handleEvent()
}
