<template>
  <nav class="navbar" aria-label="Main Navigation" :class="stepperClass">
    <div class="navbar-container">
      <div class="navbar-progress-bar" :style="{ width: `${progressBar.percent}%` }"></div>
      <div class="navbar-left">
        <!-- @slot left: For action buttons -->
        <slot name="left"></slot>
      </div>
      <div class="navbar-center">
        <!-- @slot default: Either a title or stepper goes here -->
        <slot></slot>
      </div>
      <div class="navbar-right">
        <!-- @slot right: For action buttons -->
        <slot name="right"></slot>
      </div>
    </div>
  </nav>
</template>

<script>
import {
  provide,
  computed,
  reactive,
  inject,
  onMounted,
  onBeforeUnmount,
} from '@vue/composition-api'
export default {
  setup() {
    const registry = inject('registry', {})

    if (registry) {
      onMounted(() => {
        registry.AppNavBar = true
      })
      onBeforeUnmount(() => {
        registry.AppNavBar = false
      })
    }

    const progressBar = reactive({
      active: false,
      percent: 0,
    })
    provide('progressBar', progressBar)

    const stepperClass = computed(() => {
      if (progressBar.active) {
        return 'navbar--stepper'
      }
    })
    return {
      progressBar,
      stepperClass,
    }
  },
}
</script>

<style scoped>
.navbar {
  @apply border-b border-gray-lighter box-content;
  @apply transition-all duration-270 ease-in-out;
  @screen sm {
    @apply mb-8;
  }
  &.navbar--stepper {
    @screen md {
      .navbar-container {
        height: 96px;
      }
      .navbar-progress-bar {
        @apply hidden;
      }
    }
  }
}
.navbar-container {
  @apply relative flex justify-center items-center;
  height: 57px; /* No Tailwind equivalent */
  @apply px-4;
  @screen sm {
    @apply h-16;
    @apply px-6;
  }
  @apply max-w-6xl mx-auto;
}

.icon {
  @apply flex justify-center items-center;
  @apply outline-none;

  ::v-deep .button--icon {
    @apply w-12 h-12;
  }

  @screen sm {
    ::v-deep .button--icon {
      @apply w-10 h-10;
    }
  }
}
.navbar-left,
.navbar-right {
  /*Make left and right grow to fill space, this makes the centered item true center*/
  @apply flex-1;
  @screen md {
    @apply flex-initial;
    min-width: 64px;
  }
}
.navbar-left {
  .icon {
    @apply -ml-3;
    @screen sm {
      @apply -ml-2;
    }
  }
}
.navbar-center {
  @apply truncate text-center;
  @apply mx-4;
  @apply type-h3;
  @screen md {
    @apply flex-1;
  }
}
.navbar-right {
  @apply flex justify-end;
  .icon {
    @apply -mr-3;
    @screen sm {
      @apply -mr-2;
    }
  }
}
.navbar-progress-bar {
  @apply absolute bottom-0 left-0;
  @apply bg-orange;
  @apply transition-all duration-270 ease-in-out;
  @apply -mb-px;
  height: 2px;
}
</style>
